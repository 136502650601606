<template>
  <div id="app" style="background:white">
    <router-view/>
  </div>
</template>
<script>
import axios from 'axios'
import Pusher from "pusher-js";

export default {
  name: 'App',
  metaInfo: {
    title: "Lg-chat'",
    titleTemplate: "%s"
  },
  watch: {
    // '$route': function(to,from) {
    //   this.checkLoggedIn(to.path,from)
    // } 
  },
  created() {
    // this.checkLoggedIn(this.$router.history.current.path,'')
  },

  async mounted() {
    axios.defaults.withCredentials = true;
    // window.Pusher = new Pusher
    // window.Echo = new Echo({
    // broadcaster: "pusher",
    // cluster: 'ap2',
    // encrypted: true,
    // key: 'localkey',
    // wsHost: "chat.lyxelandflamingotech.in",
    // wsPort: 6001,
    // wssHost: "chat.lyxelandflamingotech.in",
    // wssPort: 6001,
    // forceTLS: true,
    // // cluster: "mt1",
    // disableStats: true,
    // authorizer: (channel, options) => {
    //     return {
    //         authorize: (socketId, callback) => {
    //             axios.post('https://api.chat.lyxelandflamingotech.in/api/broadcasting/auth', {
    //                 socket_id: socketId,
    //                 channel_name: channel.name
    //             })
    //             .then(response => {
    //                 callback(false, response.data);
    //             })
    //             .catch(error => {
    //                 callback(true, error);
    //             });
    //         }
    //     };
    //   },
    // });
    // window.Echo.join('chat')
    //   .listen("MessageSent", (event) => {
    //     this.messages.push({
    //         message: event.message.message,
    //         message_id: event.message.id,
    //         created_at: event.message.created_at,
    //         user: event.user
    //     });

    //     this.users.forEach((user, index) => {
    //         if (user.id === event.user.id) {
    //             user.typing = false;
    //             this.$set(this.users, index, user);
    //         }
    //     });
    //   });
    // await axios.get("https://lg-spa-laravel.test/sanctum/csrf-cookie");

    // await axios.post("https://lg-spa-laravel.test/login", this.data);

    // const { data } = await axios.get("https://lg-spa-laravel.test/api/user");

    // let echo = new Echo({
    //   broadcaster: "pusher",
    //   key: "s3cr3t",
    //   wsHost: "lg-spa-laravel.test",
    //   wsPort: 6001,
    //   wssHost: "lg-spa-laravel.test",
    //   wssPort: 6001,
    //   forceTLS: false,
    //   // cluster: "mt1",
    //   disableStats: true,
    //   authorizer: (channel, options) => {
    //     console.log(options);
    //     return {
    //       authorize: (socketId, callback) => {
    //         axios({
    //           method: "POST",
    //           url: "https://lg-spa-laravel.test/api/broadcasting/auth",
    //           data: {
    //             socket_id: socketId,
    //             channel_name: channel.name,
    //           },
    //         })
    //           .then((response) => {
    //             callback(false, response.data);
    //           })
    //           .catch((error) => {
    //             callback(true, error);
    //           });
    //       },
    //     };
    //   },
    // });

    // echo
    //   .private(`App.Models.User.${data.id}`)
    //   .listen(".new-message-event", (message) => {
    //     console.log(message);
    //   });
  },
  methods: {
    checkLoggedIn(currentPath,from){
      if(Object.keys(localStorage).includes('userData'))
      {
        let token = JSON.parse(localStorage.userData).token
        axios.defaults.headers.common= {'Authorization': `Bearer ${token}`}
        if (currentPath == "/reg" ) {
          this.$router.push("/");
        }
      } 
      else {
        this.$router.push("/"); 
        // if(from.path )
        //   window.location.reload()
        // else {
        //   setTimeout(function(){
        //     window.scrollTo(0, 0)  
        //   },200)
        // }  
      }
    }   
  }  
}
</script>
<style lang="scss">


nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
